import {
  ApolloError,
  gql,
  MutationHookOptions,
  useMutation,
} from "@apollo/client";
import { useCallback } from "react";
import { toast } from "react-toastify";
import { SkillAssessment } from "types/skills";

type GetEmployeeSkillAssessmentsData = {
  data: SkillAssessment[];
};

type GetEmployeeSkillAssessmentVars = {
  employeeId: string;
};

export const GET_EMPLOYEE_SKILL_ASSESSMENTS = gql`
  mutation GetEmployeeSkillAssessments($employeeId: UUID!) {
    data: get_employee_skill_assessments(
      employee_id: $employeeId
      sync_with_gdrive: true
    ) {
      name
      proficiency
    }
  }
`;

export const useSyncEmployeeSkillsAssessment = (
  employeeId: string,
  options?: MutationHookOptions<
    GetEmployeeSkillAssessmentsData,
    GetEmployeeSkillAssessmentVars
  >
) => {
  const [getEmployeeSkillAssessmentsMutation, data] = useMutation<
    GetEmployeeSkillAssessmentsData,
    GetEmployeeSkillAssessmentVars
  >(GET_EMPLOYEE_SKILL_ASSESSMENTS, options);

  const syncEmployeeSkillAssessments = useCallback(async () => {
    try {
      await getEmployeeSkillAssessmentsMutation({
        variables: { employeeId },
      });
    } catch (ex) {
      toast.error((ex as ApolloError)?.message);
    }
    return data;
  }, [getEmployeeSkillAssessmentsMutation, employeeId, data]);

  return {
    syncEmployeeSkillAssessments,
    ...data,
  };
};
