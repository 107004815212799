import { useMemo } from "react";
import { gql, QueryHookOptions, QueryResult, useQuery } from "@apollo/client";
import { Resume } from "pages/employee/components/employeeResumes/types";

type GetEmployeeResumesData = {
  resumes: (Resume & {
    createdAt: string;
    updatedAt: string;
  })[];
};

type GetEmployeeResumesVars = {
  employeeId: string;
};

type HookType = QueryResult<GetEmployeeResumesData, GetEmployeeResumesVars> & {
  data: GetEmployeeResumesData;
};

export const GET_EMPLOYEE_RESUMES = gql`
  query GetEmployeeResumes($employeeId: uuid!) {
    resumes: employee_resumes(
      where: { employee_id: { _eq: $employeeId } }
      order_by: { updated_at: desc }
    ) {
      id
      employeeId: employee_id
      employeeName: employee_name
      role
      summary
      withCompanyBranding: with_company_branding
      documentName: document_name
      comment
      skills: skills_snapshot
      experiences: experiences_snapshot
      educations: educations_snapshot
      certificates: certificates_snapshot
      createdAt: created_at
      updatedAt: updated_at
      company
      receiver
    }
  }
`;

export const useEmployeeResumes = (
  options?: QueryHookOptions<GetEmployeeResumesData, GetEmployeeResumesVars>
): HookType => {
  const { data, ...rest } = useQuery<
    GetEmployeeResumesData,
    GetEmployeeResumesVars
  >(GET_EMPLOYEE_RESUMES, options);

  const parsedData = useMemo<GetEmployeeResumesData>(
    () => ({
      resumes: data?.resumes ?? [],
    }),
    [data]
  );

  return { data: parsedData, ...rest };
};
