import { gql, QueryHookOptions, QueryResult, useQuery } from "@apollo/client";
import { useMemo } from "react";
import { TicketsManageContext } from "types/tickets";
import {TICKET_STATUS_ENUM, TICKET_STATUS_LABELS_MAPPING} from "../../config/constants";

type GetTicketsManageContextData = TicketsManageContext;

type HookType = QueryResult<GetTicketsManageContextData> & {
  data: GetTicketsManageContextData;
};

const GET_TICKETS_MANAGE_CONTEXT = gql`
  query getTicketsManageContext {
    managers(
      where: { manager_employee_profile: { status: { _neq: ARCHIVED } } }
    ) {
      id
      firstName: first_name
      lastName: last_name
      profile: manager_employee_profile {
        department {
          name
        }
      }
    }
    employees {
      id
      firstName: first_name
      lastName: last_name
    }
    projects {
      id
      name
      status
    }
    types: manager_tasks_type {
      value
      label
    }
    statuses: manager_tasks_status {
      value
      label
    }
  }
`;

export const useTicketsManageContext = (
  options?: QueryHookOptions<GetTicketsManageContextData>
): HookType => {
  const { data, ...rest } = useQuery<GetTicketsManageContextData>(
    GET_TICKETS_MANAGE_CONTEXT,
    options
  );

  const parsedData = useMemo<GetTicketsManageContextData>(
    () => ({
      managers: data?.managers ?? [],
      employees: data?.employees ?? [],
      projects: data?.projects ?? [],
      types: data?.types ?? [],
      statuses: data?.statuses ? data?.statuses.map(
        (status) => {
          return {
            label: TICKET_STATUS_LABELS_MAPPING[status.value as TICKET_STATUS_ENUM],
            value: status.value,
          }
        }) : [],
    }),
    [data]
  );

  return {
    data: parsedData,
    ...rest,
  };
};
