import { gql, useQuery } from "@apollo/client";
import moment from "moment";
import { EmployeeExtended } from "pages/employees/Employees.page";
import { useMemo } from "react";
import { Project } from "types/assignments";
import { Employee } from "types/employee";

interface GetEmployeesVars {
  startDate: string;
  endDate: string;
  assignmentsDate: string;
}

export interface GetEmployeesData {
  employees: Array<
    Pick<
      Employee,
      | "id"
      | "email"
      | "firstName"
      | "lastName"
      | "status"
      | "picture"
      | "role"
      | "directSupervisor"
      | "department"
      | "skills"
    > & {
      projects: { project: Pick<Project, "id" | "name"> }[];
      personal?: { grade?: { id: string; label: string } };
    }
  >;
}

export const GET_EMPLOYEES = gql`
  query getEmployees(
    $startDate: timestamptz!
    $endDate: timestamptz!
    $assignmentsDate: timestamptz!
  ) {
    employees(
      where: {
        _or: [
          { status: { _is_null: false } }
          {
            work_logs: {
              start_time: { _gte: $startDate }
              end_time: { _lte: $endDate }
            }
          }
        ]
      }
      order_by: { last_name: asc }
    ) {
      id
      email
      picture
      firstName: first_name
      lastName: last_name
      status
      directSupervisor: direct_supervisor {
        firstName: first_name
        lastName: last_name
      }
      department {
        name
      }
      projects: project_employee_assignments(
        distinct_on: project_id
        where: {
          start_date: { _lte: $assignmentsDate }
          end_date: { _gte: $assignmentsDate }
        }
      ) {
        project {
          id
          name
        }
      }
      role {
        id
        name: erp_employee_role
      }
      skills: employee_skill_assessments {
        name: skill
        proficiency
      }
      personal: personal_details {
        id
        grade {
          id
          label
        }
      }
    }
  }
`;

export const useEmployees = () => {
  const todayISOString = moment().endOf("day").toISOString();
  const { data, loading, error } = useQuery<GetEmployeesData, GetEmployeesVars>(
    GET_EMPLOYEES,
    {
      variables: {
        startDate: moment().startOf("month").toISOString(),
        endDate: moment().endOf("month").toISOString(),
        assignmentsDate: todayISOString,
      },
      fetchPolicy: "no-cache",
    }
  );

  const preparedData = useMemo<{
    employees: EmployeeExtended[];
  }>(
    () => ({
      employees:
        data?.employees?.map(
          ({
            role,
            projects,
            department,
            directSupervisor,
            personal,
            ...restProps
          }) => {
            return {
              ...restProps,
              role: role?.name ?? "",
              department: department?.name ?? "",
              directSupervisorName: directSupervisor
                ? `${directSupervisor?.lastName} ${directSupervisor?.firstName}`
                : "",
              projects: projects.map(({ project }) => project),
              grade: personal?.grade?.label,
            };
          }
        ) || [],
    }),
    [data?.employees]
  );
  return {
    data: preparedData,
    loading,
    error,
  };
};
