import { useMemo } from "react";
import { gql, QueryResult, useQuery } from "@apollo/client";
import moment from "moment";
import { Project } from "types/assignments";

interface GetProjectsData {
  projects: Pick<
    Project,
    | "id"
    | "name"
    | "status"
    | "startDate"
    | "endDate"
    | "headcount"
    | "billableCount"
    | "nonBillableCount"
    | "deliveryManagerId"
  >[];
}

interface GetProjectsVars {
  startDate: string;
  endDate: string;
}

type HookType = QueryResult<GetProjectsData, GetProjectsVars> & {
  data: GetProjectsData;
};

export const GET_PROJECTS = gql`
  query getProjects($startDate: timestamptz, $endDate: timestamptz) {
    projects(
      where: {
        _or: [
          { status: { _is_null: false } }
          {
            project_employee_assignments: {
              work_logs: {
                start_time: { _gte: $startDate }
                end_time: { _lte: $endDate }
              }
            }
          }
        ]
      }
      order_by: { name: asc }
    ) {
      id
      name
      status
      startDate: start_date
      endDate: end_date
      deliveryManagerId: supervisor_id
      headcount(args: {})
      billableCount: headcount(args: { billable: true })
      nonBillableCount: headcount(args: { billable: false })
    }
  }
`;

export const useProjects = (): HookType => {
  const { data, ...rest } = useQuery<GetProjectsData, GetProjectsVars>(
    GET_PROJECTS,
    {
      variables: {
        startDate: moment().startOf("month").toISOString(),
        endDate: moment().endOf("month").toISOString(),
      },
    }
  );

  const parsedData = useMemo<GetProjectsData>(
    () => ({
      projects: data?.projects ?? [],
    }),
    [data]
  );

  return { data: parsedData, ...rest };
};
