import { ApolloError, gql, useMutation } from "@apollo/client";
import { useCallback } from "react";
import { toast } from "react-toastify";
import { EmployeeDocument } from "types/employee";

type GetEmployeeDocumentsData = {
  data: EmployeeDocument[];
};

type GetEmployeeDocumentsVars = {
  employeeId: string;
};

export const GET_EMPLOYEE_DOCUMENTS = gql`
  mutation ListEmployeeDocuments($employeeId: UUID!) {
    data: list_employee_documents(employee_id: $employeeId, sync_with_gdrive: true) {
      id
      link
      created
      modified
      name
      type
      description
      google_drive_id
      additional_info
    }
  }
`;

export const useGetEmployeeDocuments = (employeeId: string) => {
  const [listEmployeeDocumentsMutation, { loading, error, data }] = useMutation<
    GetEmployeeDocumentsData,
    GetEmployeeDocumentsVars
  >(GET_EMPLOYEE_DOCUMENTS);

  const listEmployeeDocuments = useCallback(
    async () => {
      try {
        await listEmployeeDocumentsMutation({
          variables: { employeeId },
        });
      } catch (ex) {
        toast.error((ex as ApolloError)?.message);
      }
    },
    [listEmployeeDocumentsMutation, employeeId]
  );

  return {
    listEmployeeDocuments,
    loading,
    error,
    documents: data?.data ?? [],
  };
};
