export type NotificationMessage = {
  id: string;
  type: NOTIFICATION_TYPE;
  text: string | null;
  title: string | null;
  actionParams: string | null;
  actionType: NOTIFICATION_ACTION_TYPE;
  createdAt: string;
  category: NOTIFICATION_CATEGORY | null;
};

export type NotificationAssignment = {
  id: string;
  notificationId: string;
  employeeId: string | null;
  managerId: string | null;
  status: NOTIFICATION_STATUS;
  message: NotificationMessage;
  reviewedAt: string;
  modifiedAt: string;
};

export enum NOTIFICATION_TYPE {
  INFO = "INFO",
  WARNING = "WARNING",
}

export enum NOTIFICATION_STATUS {
  UNREAD = "UNREAD",
  READ = "READ",
  DELETED = "DELETED",
}

export enum NOTIFICATION_ACTION_TYPE {
  INFO = "INFO",
  REDIRECT = "REDIRECT",
}

export enum NOTIFICATION_CATEGORY {
  EMPLOYEES = "EMPLOYEES",
  PROJECTS = "PROJECTS",
  TIMESHEETS = "TIMESHEETS",
  PROCESSES = "PROCESSES",
}
