import {
  Content,
  DropdownMenuContentProps,
  DropdownMenuTriggerProps,
  DropdownMenuPortalProps,
  Portal,
  DropdownMenuProps as DropdownMenuRootProps,
  Root,
  Trigger,
} from "@radix-ui/react-dropdown-menu";
import React, { FC, ReactNode } from "react";

export type DropdownProps = {
  triggerContent: ReactNode;
  contentContent: ReactNode;
  align?: "center" | "start" | "end";
  rootProps?: DropdownMenuRootProps;
  contentProps?: DropdownMenuContentProps;
  portalProps?: DropdownMenuPortalProps;
  triggerProps?: DropdownMenuTriggerProps;
};

export const Dropdown: FC<DropdownProps> = ({
  triggerContent,
  contentContent,
  align = "start",
  rootProps,
  contentProps,
  portalProps,
  triggerProps,
}) => {
  return (
    <Root {...rootProps}>
      <Trigger asChild {...triggerProps}>
        {triggerContent}
      </Trigger>
      <Portal {...portalProps}>
        <Content asChild align={align} {...contentProps}>
          {contentContent}
        </Content>
      </Portal>
    </Root>
  );
};
