import { ApolloError, gql, useMutation } from "@apollo/client";
import { useCallback } from "react";
import { toast } from "react-toastify";
import { ProjectDocument } from "types/project";

type GetProjectDocumentsData = {
  data: ProjectDocument[];
};

type GetProjectDocumentsVars = {
  projectId: string;
};

export const GET_PROJECT_DOCUMENTS = gql`
  mutation ListProjectDocuments($projectId: UUID!) {
    data: list_project_documents(project_id: $projectId, sync_with_gdrive: true) {
      link
      created
      modified
      name
      type
      description
      google_drive_id
      additional_info
    }
  }
`;

export const useGetProjectDocuments = (projectId: string) => {
  const [listProjectDocumentsMutation, { loading, error, data }] = useMutation<
    GetProjectDocumentsData,
    GetProjectDocumentsVars
  >(GET_PROJECT_DOCUMENTS);

  const listProjectDocuments = useCallback(
    async () => {
      try {
        await listProjectDocumentsMutation({
          variables: { projectId },
        });
      } catch (ex) {
        toast.error((ex as ApolloError)?.message);
      }
    },
    [listProjectDocumentsMutation, projectId]
  );

  return {
    listProjectDocuments,
    loading,
    error,
    documents: data?.data ?? [],
  };
};
