import { gql, useQuery } from "@apollo/client";
import { useURLSearchParams } from "hooks";
import { useMemo } from "react";
import { Project } from "types/assignments";
import { Department, Employee, Role } from "types/employee";
import { Category, Subcategory } from "types/career-path";
import {Manager} from "../../types/general";

type CareerPathSubcategory = Pick<
  Subcategory,
  "id" | "name" | "category" | "questions"
>;
type CareerPathCategory = Category & { subcategories: CareerPathSubcategory[] };

export interface GetFiltersData {
  employees: Pick<Employee, "id" | "firstName" | "lastName">[];
  departments: Department[];
  projects: Pick<Project, "id" | "name">[];
  roles: Role[];
  categories: CareerPathCategory[];
  managers: Pick<Manager, "id" | "firstName" | "lastName" | "profile">[];
}

export interface GetFiltersVars {
  version: Record<"id", { _eq: string }> | Record<"current", { _eq: boolean }>;
}

export const GET_FILTERS = gql`
  query filters($version: career_path_versions_bool_exp) {
    categories: career_path_categories(
      where: {
        career_path_subcategories: {
          career_path_questions: { career_path_version: $version }
        }
      }
      order_by: { order: asc }
    ) {
      id
      name
      subcategories: career_path_subcategories(order_by: { order: asc }) {
        id
        name
      }
    }
    employees(order_by: { last_name: asc }) {
      id
      firstName: first_name
      lastName: last_name
    }
    departments: employee_departments(order_by: { name: asc }) {
      id
      name
    }
    projects(where: { status: { _eq: ACTIVE } }, order_by: { name: asc }) {
      id
      name
    }
    roles: erp_employee_role(order_by: { erp_employee_role: asc }) {
      id
      name: erp_employee_role
    }
    managers {
      id
      firstName: first_name
      lastName: last_name
      profile: manager_employee_profile {
        status
      }
    }
  }
`;

export const useGetFilters = () => {
  const { searchParams } = useURLSearchParams();
  const versionIdParam = searchParams.get("versionId");

  const { data, loading, error, refetch } = useQuery<
    GetFiltersData,
    GetFiltersVars
  >(GET_FILTERS, {
    variables: {
      version: versionIdParam
        ? { id: { _eq: versionIdParam } }
        : { current: { _eq: true } },
    },
  });

  const preparedData = useMemo<GetFiltersData>(
    () => ({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      employees: data?.employees ?? [],
      departments: data?.departments ?? [],
      projects: data?.projects ?? [],
      roles: data?.roles ?? [],
      categories: data?.categories ?? [],
      managers: data?.managers ?? [],
    }),
    [data]
  );

  return {
    data: preparedData,
    loading,
    error,
    refetch,
  };
};
