import {
  DrawerProps as MuiDrawerProps,
  Drawer as MuiDrawer,
} from "@mui/material";
import { Button } from "components";
import React, { FC, PropsWithChildren } from "react";
import { Arrow4Icon } from "resources/icons";

interface DrawerProps
  extends PropsWithChildren,
    Omit<MuiDrawerProps, "open" | "onClose"> {
  isOpen: boolean;
  title: string;
  subtitle?: string;
  onClose: (event?: object, reason?: "backdropClick" | "escapeKeyDown") => void;
  action?: {
    title: string;
    onAction: (() => void) | undefined;
    disabled: boolean;
  };
  wide?: boolean | undefined;
}

export const Drawer: FC<DrawerProps> = ({
  isOpen,
  anchor = "right",
  title,
  subtitle,
  onClose,
  action,
  wide = false,
  children,
  ...restProps
}) => {
  return (
    <MuiDrawer
      anchor={anchor}
      open={isOpen}
      onClose={(event, reason) => {
        if (reason === "backdropClick") return;
        onClose(event, reason);
      }}
      classes={{
        paper: wide ?
          "w-full sm:max-w-176 xs:px-5 sm:px-8 py-8 shadow-1"
          : "w-full sm:max-w-115 xs:px-5 sm:px-8 py-8 shadow-1",
        modal: "z-50",
      }}
      PaperProps={{ role: "dialog" }}
      slotProps={{
        backdrop: { className: "bg-transparent" },
      }}
      {...restProps}
    >
      <div className="flex justify-between xs:mb-8 sm:mb-10">
        <span className="flex flex-col">
          <span className="flex">
            <Button
              aria-label="close drawer"
              className="mr-2 p-0 sm:hidden"
              onClick={onClose}
            >
              <Arrow4Icon />
            </Button>
            <p className="font-bold">{title}</p>
          </span>
          {subtitle && <p className="mt-2 text-sm font-normal">{subtitle}</p>}
        </span>
        {action && (
          <Button
            aria-label={action.title.toLowerCase()}
            className="mr-2 p-0 sm:hidden"
            onClick={action.onAction}
            disabled={action.disabled}
          >
            <p
              className={`${
                action.disabled ? "text-grey-2" : "text-purple-2"
              } text-sm font-medium`}
            >
              {action.title}
            </p>
          </Button>
        )}
      </div>
      {children}
    </MuiDrawer>
  );
};
