import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useAuthContext } from "../../contexts";

function Provider({ children }: { children: ReactNode }) {
  const { token, logout } = useAuthContext();
  const getHasuraToken = useCallback(() => {
    return token;
  }, [token]);

  const [, setHasuraToken] = useState(getHasuraToken());

  const handleTokenChange = useCallback(() => {
    setHasuraToken(getHasuraToken());
  }, [getHasuraToken]);

  useEffect(() => {
    window.addEventListener("storage", handleTokenChange);
    return () => {
      window.removeEventListener("storage", handleTokenChange);
    };
  }, [handleTokenChange]);

  const client = useMemo(() => {
    const authLink = setContext(async (_, { headers }) => {
      return {
        headers: {
          ...headers,
          Authorization: token ? `Bearer ${token}` : "",
        },
      };
    });

    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ extensions }) => {
          if (extensions?.code === "invalid-jwt") logout();
        });
      if (networkError) console.log(`[Network error]: ${networkError}`);
    });

    const httpLink = new HttpLink({
      // eslint-disable-next-line no-undef
      uri: process.env.REACT_APP_HASURA_URL || "",
      credentials: "same-origin",
      headers: {},
    });

    return new ApolloClient({
      link: ApolloLink.from([errorLink, authLink, httpLink]),
      cache: new InMemoryCache(),
    });
  }, [token, logout]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}

export default Provider;
