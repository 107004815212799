import { gql, QueryHookOptions, QueryResult, useQuery } from "@apollo/client";
import { useMemo } from "react";
import { RawTicket } from "types/tickets";

type GetTicketsData = {
  tickets: RawTicket[];
};

type HookType = QueryResult<GetTicketsData> & {
  data: GetTicketsData;
};

export const GET_TICKETS = gql`
  query getTickets {
    tickets: manager_tasks_tasks {
      id
      type
      status
      assignedToId: assigned_to
      createdById: created_by
      title
      description
      createdAt: created_at
      details
      ticketHistory: task_histories(order_by: { change_time: desc }) {
        id
        changeTime: change_time
        managerId: user
        title
        status
        assignedTo {
          firstName: first_name
          lastName: last_name
        }
        description
        comment
      }
    }
  }
`;

export const useTickets = (
  options?: QueryHookOptions<GetTicketsData>
): HookType => {
  const { data, ...rest } = useQuery<GetTicketsData>(GET_TICKETS, {
    fetchPolicy: "network-only",
    ...options,
  });

  const parsedData: GetTicketsData = useMemo(
    () => ({
      tickets: data?.tickets ?? [],
    }),
    [data]
  );

  return { data: parsedData, ...rest };
};
