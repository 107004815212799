import { useGetNotifications } from "hooks";
import { useCallback, useMemo, useState } from "react";
import { NOTIFICATIONS_TAB } from "types/tabs";

const LIMIT = 10;

export type NotificationsPaginationTabs = {
  pageCount: number;
  currentPage: number;
  offset: number;
};

export const useNotificationsInfo = () => {
  const [offsetAll, setOffsetAll] = useState(0);
  const [offsetEmployeeAndProjects, setOffsetEmployeeAndProjects] = useState(0);
  const [offsetTimesheets, setOffsetTimesheets] = useState(0);
  const [offsetProcesses, setOffsetProcesses] = useState(0);

  const {
    notificationsAll,
    notificationsEmployeeAndProjects,
    notificationsTimesheets,
    notificationsProcesses,
    counterAll,
    counterEmployeeAndProjects,
    counterTimesheets,
    counterProcesses,
    loading,
    error,
  } = useGetNotifications({
    variables: { offsetAll, offsetEmployeeAndProjects, offsetTimesheets, offsetProcesses, limit: LIMIT },
  });

  const changePage = useCallback((tab: NOTIFICATIONS_TAB, page: number) => {
    if (tab === NOTIFICATIONS_TAB.ALL) {
      setOffsetAll(page * LIMIT);
    } else if (tab === NOTIFICATIONS_TAB.EMPLOYEE_AND_PROJECTS) {
      setOffsetEmployeeAndProjects(page * LIMIT);
    } else if (tab === NOTIFICATIONS_TAB.TIMESHEETS) {
      setOffsetTimesheets(page * LIMIT);
    } else if (tab === NOTIFICATIONS_TAB.PROCESSES) {
      setOffsetProcesses(page * LIMIT);
    }
  }, []);

  const { paginationAll, paginationEmployeeAndProjects, paginationTimesheets, paginationProcesses } = useMemo(
    () => ({
      paginationEmployeeAndProjects: {
        pageCount: Math.ceil(counterEmployeeAndProjects / LIMIT),
        currentPage: offsetEmployeeAndProjects / LIMIT,
        offset: offsetEmployeeAndProjects,
      },
      paginationTimesheets: {
        pageCount: Math.ceil(counterTimesheets / LIMIT),
        currentPage: offsetTimesheets / LIMIT,
        offset: offsetTimesheets,
      },
      paginationProcesses: {
        pageCount: Math.ceil(counterProcesses / LIMIT),
        currentPage: offsetProcesses / LIMIT,
        offset: offsetProcesses,
      },
      paginationAll: {
        pageCount: Math.ceil(counterAll / LIMIT),
        currentPage: offsetAll / LIMIT,
        offset: offsetAll,
      },
    }),
    [
      counterAll,
      counterEmployeeAndProjects,
      counterTimesheets,
      counterProcesses,
      offsetAll,
      offsetEmployeeAndProjects,
      offsetTimesheets,
      offsetProcesses
    ]
  );

  return {
    counterAll,
    counterEmployeeAndProjects,
    counterTimesheets,
    counterProcesses,
    notificationsAll,
    notificationsEmployeeAndProjects,
    notificationsTimesheets,
    notificationsProcesses,
    loading,
    error,
    changePage,
    paginationAll,
    paginationEmployeeAndProjects,
    paginationTimesheets,
    paginationProcesses,
  };
};
