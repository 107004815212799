import { ApolloError, gql, useMutation } from "@apollo/client";
import { useCallback } from "react";
import { toast } from "react-toastify";
import { ProjectDocumentTemplate } from "types/project";

export const GET_PROJECT_DOCUMENT_TEMPLATES = gql`
  mutation getProjectDocumentsTemplates {
    data: get_project_document_templates {
      value
      label
    }
  }
`;

type DocumentTemplates = {
  data: ProjectDocumentTemplate[],
}

export const useGetProjectDocumentTemplates = () => {
  const [getProjectDocumentTemplatesMutation, { loading, error, data }] = useMutation<
    DocumentTemplates
  >(GET_PROJECT_DOCUMENT_TEMPLATES);

  const getProjectDocumentTemplates = useCallback(
    async () => {
      try {
        await getProjectDocumentTemplatesMutation();
      } catch (ex) {
        toast.error((ex as ApolloError)?.message);
      }
    },
    [getProjectDocumentTemplatesMutation]
  );

  return {
    getProjectDocumentTemplates,
    loading,
    error,
    templates: data?.data,
  };
};
