import { Badge, IconButton } from "@mui/material";
import React, { FC, useState } from "react";
import { NotificationIcon } from "resources/icons";
import { NotificationsSidebar } from "../notificationsSidebar/NotificationsSidebar";
import { useNotificationsInfo } from "./useNotificationsInfo";

export const NotificationsInfo: FC = () => {
  const [isSidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const {
    counterAll,
    counterEmployeeAndProjects,
    counterTimesheets,
    counterProcesses,
    changePage,
    paginationAll,
    paginationEmployeeAndProjects,
    paginationTimesheets,
    paginationProcesses,
    notificationsAll,
    notificationsEmployeeAndProjects,
    notificationsTimesheets,
    notificationsProcesses,
    loading,
    error,
  } = useNotificationsInfo();

  return (
    <>
      <IconButton
        aria-label="show notifications"
        className="text-purple-1"
        onClick={() => setSidebarOpen(true)}
      >
        <Badge
          badgeContent={counterEmployeeAndProjects + counterTimesheets + counterProcesses}
          classes={{ badge: "bg-purple-3 text-white" }}
          overlap="circular"
        >
          <NotificationIcon />
        </Badge>
      </IconButton>

      {isSidebarOpen && (
        <NotificationsSidebar
          handleClose={() => setSidebarOpen(false)}
          notificationsAll={notificationsAll}
          notificationsEmployeeAndProjects={notificationsEmployeeAndProjects}
          notificationsTimesheets={notificationsTimesheets}
          notificationsProcesses={notificationsProcesses}
          changePage={changePage}
          paginationAll={paginationAll}
          paginationEmployeeAndProjects={paginationEmployeeAndProjects}
          paginationTimesheets={paginationTimesheets}
          paginationProcesses={paginationProcesses}
          counterEmployeeAndProjects={counterEmployeeAndProjects}
          counterTimesheets={counterTimesheets}
          counterProcesses={counterProcesses}
          counterAll={counterAll}
          loading={loading}
          error={error}
        />
      )}
    </>
  );
};
