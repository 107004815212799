import { QueryHookOptions, gql, useQuery } from "@apollo/client";
import { useAuthContext } from "contexts";
import { useMemo } from "react";
import {
  NOTIFICATION_STATUS,
  NotificationAssignment,
} from "types/notifications";

const notificationAssignmentFragment = gql`
  fragment NotificationAssignmentData on notifications_assignments {
    id
    status
    notificationId: notification_id
    reviewedAt: reviewed_at
    modifiedAt: modified_at
    message {
      actionParams: action_params
      actionType: action_type
      text
      title
      type
      createdAt: created_at
      category
    }
  }
`;

export const GET_MANAGER_ALL_NOTIFICATIONS = gql`
  query getManagerAllNotifications(
    $managerId: uuid,
    $limit: Int,
    $offsetAll: Int,
    $offsetEmployeeAndProjects: Int,
    $offsetTimesheets: Int,
    $offsetProcesses: Int
  ) {
    notificationsAll: notifications_assignments(
      limit: $limit,
      offset: $offsetAll,
      order_by: { message: { created_at: desc } },
      where: {
        notifications_status: { value: { _neq: ${NOTIFICATION_STATUS.DELETED} } }
        employee_id: { _is_null: true }
        manager_id: { _eq: $managerId }
      }
    ) {
      ...NotificationAssignmentData
      managerId: manager_id
    }
    allCounter: notifications_assignments_aggregate(
      where: {
        notifications_status: { value: { _neq: ${NOTIFICATION_STATUS.DELETED} } }
        employee_id: { _is_null: true }
        manager_id: { _eq: $managerId }
      }
    ) {
      aggregate {
        count
      }
    }
    notificationsEmployeeAndProjects: notifications_assignments(
      limit: $limit,
      offset: $offsetEmployeeAndProjects,
      order_by: { message: { created_at: desc } },
      where: {
        notifications_status: { value: { _eq: ${NOTIFICATION_STATUS.UNREAD} } }
        employee_id: { _is_null: true }
        manager_id: { _eq: $managerId }
        message: { category: { _in: [ PROJECTS, EMPLOYEES ] } }
      }
    ) {
      ...NotificationAssignmentData
      managerId: manager_id
    }
    employeeAndProjectsCounter: notifications_assignments_aggregate(
      where: {
        notifications_status: { value: { _eq: ${NOTIFICATION_STATUS.UNREAD} } }
        employee_id: { _is_null: true }
        manager_id: { _eq: $managerId }
        message: { category: { _in: [ PROJECTS, EMPLOYEES ] } }
      }
    ) {
      aggregate {
        count
      }
    }
    notificationsTimesheets: notifications_assignments(
      limit: $limit,
      offset: $offsetTimesheets,
      order_by: { message: { created_at: desc } },
      where: {
        notifications_status: { value: { _eq: ${NOTIFICATION_STATUS.UNREAD} } }
        employee_id: { _is_null: true }
        manager_id: { _eq: $managerId }
        message: { category: { _eq: TIMESHEETS } }
      }
    ) {
      ...NotificationAssignmentData
      managerId: manager_id
    }
    timesheetsCounter: notifications_assignments_aggregate(
      where: {
        notifications_status: { value: { _eq: ${NOTIFICATION_STATUS.UNREAD} } }
        employee_id: { _is_null: true }
        manager_id: { _eq: $managerId }
        message: { category: { _eq: TIMESHEETS } }
      }
    ) {
      aggregate {
        count
      }
    }
    notificationsProcesses: notifications_assignments(
      limit: $limit,
      offset: $offsetProcesses,
      order_by: { message: { created_at: desc } },
      where: {
        notifications_status: { value: { _eq: ${NOTIFICATION_STATUS.UNREAD} } }
        employee_id: { _is_null: true }
        manager_id: { _eq: $managerId }
        message: { category: { _eq: PROCESSES } }
      }
    ) {
      ...NotificationAssignmentData
      managerId: manager_id
    }
    processesCounter: notifications_assignments_aggregate(
      where: {
        notifications_status: { value: { _eq: ${NOTIFICATION_STATUS.UNREAD} } }
        employee_id: { _is_null: true }
        manager_id: { _eq: $managerId }
        message: { category: { _eq: PROCESSES } }
      }
    ) {
      aggregate {
        count
      }
    }
  }
  ${notificationAssignmentFragment}
`;

export const GET_EMPLOYEE_ALL_NOTIFICATIONS = gql`
  query getEmployeeAllNotifications($employeeId: uuid, $limit: Int, $offsetAll: Int, $offsetTimesheets: Int) {
    notificationsAll: notifications_assignments(
      limit: $limit,
      offset: $offsetAll,
      order_by: { message: { created_at: desc } },
      where: {
        notifications_status: { value: { _neq: ${NOTIFICATION_STATUS.DELETED} } }
        manager_id: { _is_null: true }
        employee_id: { _eq: $employeeId }
      }
    ) {
      ...NotificationAssignmentData
      employeeId: employee_id
    }
    allCounter: notifications_assignments_aggregate(
      where: {
        notifications_status: { value: { _neq: ${NOTIFICATION_STATUS.DELETED} } }
        manager_id: { _is_null: true }
        employee_id: { _eq: $employeeId }
      }
    ) {
      aggregate {
        count
      }
    }
    notificationsTimesheets: notifications_assignments(
      limit: $limit,
      offset: $offsetTimesheets,
      order_by: { message: { created_at: desc } },
      where: {
        notifications_status: { value: { _eq: ${NOTIFICATION_STATUS.UNREAD} } }
        manager_id: { _is_null: true }
        employee_id: { _eq: $employeeId }
        message: { category: { _eq: TIMESHEETS } }
      }
    ) {
      ...NotificationAssignmentData
      employeeId: employee_id
    }
    timesheetsCounter: notifications_assignments_aggregate(
      where: {
        notifications_status: { value: { _eq: ${NOTIFICATION_STATUS.UNREAD} } }
        manager_id: { _is_null: true }
        employee_id: { _eq: $employeeId }
        message: { category: { _eq: TIMESHEETS } }
      }
    ) {
      aggregate {
        count
      }
    }
  }
  ${notificationAssignmentFragment}
`;

export interface GetNotificationsData {
  notificationsAll: Array<NotificationAssignment>;
  notificationsEmployeeAndProjects: Array<NotificationAssignment>;
  notificationsTimesheets: Array<NotificationAssignment>;
  notificationsProcesses: Array<NotificationAssignment>;
  allCounter: { aggregate: { count: number } };
  employeeAndProjectsCounter: { aggregate: { count: number } };
  timesheetsCounter: { aggregate: { count: number } };
  processesCounter: { aggregate: { count: number } };
}

type Only<T, U> = {
  [P in keyof T]: T[P];
} & {
  [P in keyof U]?: never;
};

type Either<T, U> = Only<T, U> | Only<U, T>;

interface GetManagerNotificationVars {
  managerId: string | undefined;
}
interface GetEmployeeNotificationsVars {
  employeeId: string | undefined;
}
type NotificationsVars = {
  limit: number;
  offsetAll: number;
  offsetEmployeeAndProjects: number;
  offsetTimesheets: number;
  offsetProcesses: number;
};
export type GetNotificationsVars = NotificationsVars &
  Either<GetManagerNotificationVars, GetEmployeeNotificationsVars>;

const useGetNotifications = (
  options: QueryHookOptions<GetNotificationsData, NotificationsVars>
) => {
  const { user, isManager } = useAuthContext();
  const employeeId = user?.userId;
  const managerId = user?.managerId;

  const { data, loading, error } = useQuery<
    GetNotificationsData,
    GetManagerNotificationVars | GetEmployeeNotificationsVars
  >(
    isManager()
      ? GET_MANAGER_ALL_NOTIFICATIONS
      : GET_EMPLOYEE_ALL_NOTIFICATIONS,
    {
      variables: isManager()
        ? { ...options.variables, managerId: managerId! }
        : { ...options.variables, employeeId: employeeId! },
      skip: isManager() ? !managerId : !employeeId,
      fetchPolicy: "network-only",
      pollInterval: 7000, // 7 sec
    }
  );

  const preparedData = useMemo(
    () => ({
      notificationsAll: data?.notificationsAll || [],
      notificationsEmployeeAndProjects: data?.notificationsEmployeeAndProjects || [],
      notificationsTimesheets: data?.notificationsTimesheets || [],
      notificationsProcesses: data?.notificationsProcesses || [],
      counterAll: data?.allCounter?.aggregate?.count || 0,
      counterEmployeeAndProjects: data?.employeeAndProjectsCounter?.aggregate?.count || 0,
      counterTimesheets: data?.timesheetsCounter?.aggregate?.count || 0,
      counterProcesses: data?.processesCounter?.aggregate?.count || 0,
    }),
    [data]
  );

  return {
    ...preparedData,
    loading,
    error,
  };
};

export { useGetNotifications };
