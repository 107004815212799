import { PageChangeBase } from "components";
import React, { useLayoutEffect } from "react";
import { PaginationProps } from "../../Pagination";
import { useURLSearchParams } from "hooks";
import { useHistory } from "react-router-dom";

type PageChangeProps<D extends object> = Pick<PaginationProps<D>, "table">;

export const PageChange = <D extends object>({ table }: PageChangeProps<D>) => {
  const { searchParams } = useURLSearchParams();
  const history = useHistory();

  const tableStateIndex = table.getState().pagination.pageIndex;

  useLayoutEffect(() => {
    const pageSearchParam = searchParams.get("page");
    if (pageSearchParam && parseInt(pageSearchParam) + 1 <= table.getPageCount()) {
      table.setPageIndex(parseInt(pageSearchParam));
    } else {
      searchParams.set("page", tableStateIndex.toString());
      history.push({ search: `?${searchParams}` });
    }
  }, [searchParams, table, tableStateIndex, history])


  return (
    <PageChangeBase
      currentPage={tableStateIndex}
      isNextDisabled={!table.getCanNextPage()}
      isPrevDisabled={!table.getCanPreviousPage()}
      goToNextPage={() => {
        searchParams.set("page", String(tableStateIndex + 1));
        history.push({ search: `?${searchParams}` });
        table.nextPage();
      }}
      goToPage={(page) => {
        searchParams.set("page", String(page));
        history.push({ search: `?${searchParams}` });
        table.setPageIndex(page);
      }}
      goToPrevPage={() => {
        searchParams.set("page", String(tableStateIndex - 1));
        history.push({ search: `?${searchParams}` });
        table.previousPage();
      }}
      pageOptions={table.getPageOptions()}
    />
  );
};
