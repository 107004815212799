import { MappedHeadersType } from "components/table/MobileTable";
import { TABLE_ENUM } from "config/table-const";
import React from "react";
import { FiltersProps } from "./Filters";
import {
  ColumnMultiFiltersMobile,
  FiltersOpenButton,
  MobileSort,
  SearchFilter,
} from "./components";
import { useFilters } from "./useFilters";

export type MobileFiltersProps<D extends object> = {
  headers: MappedHeadersType<D>;
  tableName: TABLE_ENUM;
} & Pick<
  FiltersProps<D>,
  | "table"
  | "columnMultiFilters"
  | "components"
  | "searchProps"
  | "columnAdditionalFilters"
>;

export const MobileFilters = <D extends object>({
  table,
  tableName,
  headers,
  columnMultiFilters,
  columnAdditionalFilters,
  components,
  searchProps,
}: MobileFiltersProps<D>) => {
  const globalFilter = table.options.state.globalFilter;
  const hasColumnFilters =
    columnMultiFilters?.length || columnAdditionalFilters?.length;

  const {
    applyFilters,
    applyCustomFilters,
    checkedItems,
    setCheckedItems,
    closeOpenedFilter,
    handleCheck,
    handleChange,
    clearColumn,
    isFiltersSectionOpen,
    openedFilter,
    setFiltersSectionOpen,
    toggleOpenedFilter,
    clearFilters,
    tableFiltersCount,
  } = useFilters({ table, tableName });

  return (
    <div className="flex- flex min-h-16 flex-wrap justify-between gap-5 px-5 pb-4 pt-6">
      <div className="flex w-full flex-col gap-4">
        {components?.SearchWrapperComponent ? (
          <components.SearchWrapperComponent
            table={table}
            onFiltersChange={applyCustomFilters}
          >
            <SearchFilter
              globalFilter={globalFilter}
              setGlobalFilter={table.setGlobalFilter}
              {...searchProps}
            />
          </components.SearchWrapperComponent>
        ) : (
          <SearchFilter
            globalFilter={globalFilter}
            setGlobalFilter={table.setGlobalFilter}
            {...searchProps}
          />
        )}
      </div>

      <div className="flex w-full flex-col gap-4">
        {components?.FilterWrapperComponent ? (
          <components.FilterWrapperComponent
            table={table}
            onFiltersChange={applyCustomFilters}
          >
            <div className="flex w-full justify-between">
              {hasColumnFilters ? (
                <FiltersOpenButton
                  isFiltersSectionOpen={isFiltersSectionOpen}
                  setFiltersSectionOpen={setFiltersSectionOpen}
                  tableFiltersCount={tableFiltersCount}
                />
              ) : (
                <div />
              )}
              <MobileSort<D> table={table} headers={headers} />
            </div>
          </components.FilterWrapperComponent>
        ) : (
          <div className="flex w-full justify-between">
            {hasColumnFilters ? (
              <FiltersOpenButton
                isFiltersSectionOpen={isFiltersSectionOpen}
                setFiltersSectionOpen={setFiltersSectionOpen}
                tableFiltersCount={tableFiltersCount}
              />
            ) : (
              <div />
            )}
            <MobileSort<D> table={table} headers={headers} />
          </div>
        )}
      </div>

      {isFiltersSectionOpen && hasColumnFilters && (
        <ColumnMultiFiltersMobile
          applyFilters={applyFilters}
          checkedItems={checkedItems}
          setCheckedItems={setCheckedItems}
          closeOpenedFilter={closeOpenedFilter}
          columnMultiFilters={columnMultiFilters}
          columnAdditionalFilters={columnAdditionalFilters}
          handleCheck={handleCheck}
          handleChange={handleChange}
          clearColumn={clearColumn}
          openedFilter={openedFilter}
          setFiltersSectionOpen={setFiltersSectionOpen}
          toggleOpenedFilter={toggleOpenedFilter}
          clearFilters={clearFilters}
          table={table}
        />
      )}
    </div>
  );
};
