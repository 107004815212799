import { Table } from "@tanstack/react-table";
import { DropdownMenu, TableProps } from "components";
import { TableData } from "components/table/types";
import { TABLE_ENUM } from "config/table-const";
import React, { ReactElement } from "react";
import {
  ColumnMultiFilters,
  FiltersAppliedList,
  FiltersOpenButton,
  SearchFilter,
} from "./components";
import { useFilters } from "./useFilters";
import { DownloadIcon } from "resources/icons";

export type FiltersProps<D extends TableData> = {
  table: Table<D>;
  tableName: TABLE_ENUM;
  exportFileHandler: CallableFunction | undefined;
} & Pick<
  TableProps<D>,
  | "components"
  | "searchProps"
  | "columnMultiFilters"
  | "columnAdditionalFilters"
>;

export const Filters = <D extends TableData>({
  columnMultiFilters,
  columnAdditionalFilters,
  table,
  components,
  searchProps,
  tableName,
  exportFileHandler,
}: FiltersProps<D>): ReactElement => {
  const globalFilter = table.options.state.globalFilter;
  const hasColumnFilters =
    columnMultiFilters?.length || columnAdditionalFilters?.length;
  const tableColumnFilters = table.options.state.columnFilters;

  const {
    applyFilters,
    applyCustomFilters,
    closeOpenedFilter,
    checkedItems,
    setCheckedItems,
    handleChange,
    handleCheck,
    clearColumn,
    isFiltersSectionOpen,
    openedFilter,
    setFiltersSectionOpen,
    toggleOpenedFilter,
    tableFiltersCount,
    clearFilters,
  } = useFilters({ table, tableName });

  return (
    <>
      <div className="flex w-full justify-between gap-4 p-4">
        <div className="flex flex-col gap-4 md:flex-row md:flex-wrap">
          {components?.SearchWrapperComponent ? (
            <components.SearchWrapperComponent
              table={table}
              onFiltersChange={applyCustomFilters}
            >
              <SearchFilter
                globalFilter={globalFilter}
                setGlobalFilter={table.setGlobalFilter}
                {...searchProps}
              />
            </components.SearchWrapperComponent>
          ) : (
            <SearchFilter
              globalFilter={globalFilter}
              setGlobalFilter={table.setGlobalFilter}
              {...searchProps}
            />
          )}
        </div>

        <div className="flex items-center gap-4">
          {components?.FilterWrapperComponent ? (
            <components.FilterWrapperComponent
              table={table}
              onFiltersChange={applyCustomFilters}
            >
              {hasColumnFilters && (
                <FiltersOpenButton
                  isFiltersSectionOpen={isFiltersSectionOpen}
                  setFiltersSectionOpen={setFiltersSectionOpen}
                  tableFiltersCount={tableFiltersCount}
                />
              )}
            </components.FilterWrapperComponent>
          ) : hasColumnFilters ? (
            <FiltersOpenButton
              isFiltersSectionOpen={isFiltersSectionOpen}
              setFiltersSectionOpen={setFiltersSectionOpen}
              tableFiltersCount={tableFiltersCount}
            />
          ) : null}
          {exportFileHandler && (
            <DropdownMenu
              closeOnClick
              items={[
                {
                  label: "Export employees",
                  icon: <DownloadIcon />,
                  onClick: () => exportFileHandler(),
                },
              ]}
            />
          )}
        </div>
      </div>

      {isFiltersSectionOpen && hasColumnFilters && (
        <div className="flex flex-col border border-y-grey-2 bg-grey-7 p-4">
          <ColumnMultiFilters
            applyFilters={applyFilters}
            checkedItems={checkedItems}
            setCheckedItems={setCheckedItems}
            closeOpenedFilter={closeOpenedFilter}
            columnMultiFilters={columnMultiFilters}
            columnAdditionalFilters={columnAdditionalFilters}
            handleCheck={handleCheck}
            handleChange={handleChange}
            clearColumn={clearColumn}
            openedFilter={openedFilter}
            toggleOpenedFilter={toggleOpenedFilter}
            table={table}
            clearFilters={clearFilters}
            tableFiltersCount={tableFiltersCount}
          />
          <FiltersAppliedList
            tableColumnFilters={tableColumnFilters}
            columnMultiFilters={columnMultiFilters}
            getColumn={table.getColumn}
          />
        </div>
      )}
    </>
  );
};
