import {
  ColumnFilterValue,
  CustomFiltersState,
  Table,
} from "@tanstack/react-table";
import { TableData } from "components/table/types";
import { TABLE_ENUM } from "config/table-const";
import { useConfigContext } from "contexts";
import { useIsMobile } from "hooks";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

export type UseFiltersProps<D extends TableData> = {
  table: Table<D>;
  tableName: TABLE_ENUM;
};

export type UseFilters = {
  applyFilters: (columnId?: string) => void;
  applyCustomFilters: (filters: CustomFiltersState) => void;
  closeOpenedFilter: () => void;
  toggleOpenedFilter: (columnId: string) => void;
  checkedItems: Record<string, ColumnFilterValue>;
  setCheckedItems: (
    value: SetStateAction<Record<string, ColumnFilterValue>>
  ) => void;
  handleChange: (columnId: string, item: string, value: string) => void;
  handleCheck: (columnId: string, item: string) => void;
  clearColumn: (columnId: string) => void;
  isFiltersSectionOpen: boolean;
  openedFilter: string;
  setFiltersSectionOpen: Dispatch<SetStateAction<boolean>>;
  clearFilters?: () => void;
  tableFiltersCount: number;
};

export const useFilters = <D extends TableData>({
  table,
  tableName,
}: UseFiltersProps<D>): UseFilters => {
  const { isMobile } = useIsMobile();
  const tableColumnFilters = table.options.state.columnFilters;
  const { filtersOpened, changeFiltersOpened } = useConfigContext();

  const [isFiltersSectionOpen, setFiltersSectionOpen] = useState(() =>
    filtersOpened && filtersOpened[tableName]
      ? !!filtersOpened[tableName]
      : false
  );
  const [openedFilter, setOpenedFilter] = useState<string>("");

  useEffect(() => {
    if (
      !filtersOpened ||
      !filtersOpened[tableName] ||
      filtersOpened[tableName] !== isFiltersSectionOpen
    ) {
      changeFiltersOpened(isFiltersSectionOpen, tableName);
    }
  }, [isFiltersSectionOpen, changeFiltersOpened, filtersOpened, tableName]);

  const initialCheckedItems = useMemo(
    () =>
      tableColumnFilters?.reduce(
        (obj, curr) => ({ ...obj, [curr.id]: curr.value }),
        {}
      ) || {},
    [tableColumnFilters]
  );

  const [checkedItems, setCheckedItems] = useState<
    Record<string, ColumnFilterValue>
  >(initialCheckedItems ?? {});

  const handleChange = useCallback(
    (columnId: string, item: string, value: string) => {
      setCheckedItems((prev) => {
        return { ...prev, [columnId]: { ...prev[columnId], [item]: value } };
      });
    },
    []
  );

  const handleCheck = useCallback((columnId: string, item: string) => {
    setCheckedItems((prev) => {
      const foundFilterValue = prev[columnId];
      if (foundFilterValue) {
        const { [item]: existingItem, ...rest } = foundFilterValue;
        const newValue = existingItem ? rest : { ...rest, [item]: item };
        if (Object.keys(newValue).length === 0) {
          const prevCopy = { ...prev };
          delete prevCopy[columnId];
          return prevCopy;
        }
        return { ...prev, [columnId]: newValue };
      } else {
        return { ...prev, [columnId]: { [item]: item } };
      }
    });
  }, []);

  const clearFilters = useCallback(() => {
    if (isMobile) {
      setCheckedItems({});
    } else {
      table.setColumnFilters([]);
    }
  }, [isMobile, table]);

  const toggleOpenedFilter = useCallback(
    (columnId: string) =>
      setOpenedFilter((prev) => (prev === columnId ? "" : columnId)),
    []
  );

  const closeOpenedFilter = useCallback(() => {
    setOpenedFilter("");
    setCheckedItems(initialCheckedItems ?? {});
  }, [initialCheckedItems]);

  const applyFilters = useCallback(
    (columnId?: string, values?: Record<string, ColumnFilterValue>) => {
      if (columnId) {
        const filterValue = (values ?? checkedItems)[columnId];
        // apply specified column filter
        table.getColumn(columnId)?.setFilterValue(filterValue);
      } else {
        // apply all column filters
        const filters = Object.entries(values ?? checkedItems).map((item) => ({
          id: item[0],
          value: item[1],
        }));
        table.setColumnFilters(filters);
      }
      setOpenedFilter("");
    },
    [checkedItems, setOpenedFilter, table]
  );

  const clearColumn = useCallback(
    (columnId: string) => {
      setCheckedItems((prev) => {
        const newFilters = { ...prev };
        delete newFilters[columnId];
        applyFilters(columnId, newFilters);
        return newFilters;
      });
    },
    [applyFilters]
  );

  const applyCustomFilters = useCallback(
    (customFilters: CustomFiltersState) => {
      table.setState((prev) => ({
        ...prev,
        customFilters: { ...prev.customFilters, ...customFilters },
      }));
    },
    [table]
  );

  const tableFiltersCount = useMemo(
    () =>
      tableColumnFilters?.reduce(
        (count, { value }) => count + (value ? Object.keys(value).length : 0),
        0
      ) || 0,
    [tableColumnFilters]
  );

  useEffect(() => {
    const items =
      tableColumnFilters?.reduce(
        (obj, curr) => ({ ...obj, [curr.id]: curr.value }),
        {}
      ) ?? {};
    setCheckedItems(items);
  }, [tableColumnFilters]);

  return {
    applyFilters,
    applyCustomFilters,
    closeOpenedFilter,
    toggleOpenedFilter,
    checkedItems,
    setCheckedItems,
    handleChange,
    handleCheck,
    clearColumn,
    isFiltersSectionOpen,
    openedFilter,
    setFiltersSectionOpen,
    clearFilters,
    tableFiltersCount,
  };
};
