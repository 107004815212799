import {
  ColumnFiltersState,
  ColumnFilterValue,
  Table,
} from "@tanstack/react-table";
import { Button } from "components";
import { ColumnMultiFilterType, TableData } from "components/table/types";
import React, { Fragment, ReactElement, useCallback } from "react";
import { CloseIcon } from "resources/icons";

type FiltersAppliedListProps<D extends TableData> = {
  getColumn: Table<D>["getColumn"];
  columnMultiFilters: ColumnMultiFilterType[] | undefined;
  tableColumnFilters: ColumnFiltersState | undefined;
};

export const FiltersAppliedList = <D extends TableData>({
  getColumn,
  columnMultiFilters,
  tableColumnFilters,
}: FiltersAppliedListProps<D>): ReactElement | null => {
  const removeFilter = useCallback(
    (columnId: string, valueToRemove: string) => {
      getColumn(columnId)?.setFilterValue((prev: ColumnFilterValue) => {
        const { [valueToRemove]: _, ...rest } = prev;
        return Object.keys(rest)?.length ? rest : undefined;
      });
    },
    [getColumn]
  );

  return tableColumnFilters?.length ? (
    <div className="mt-4 flex flex-wrap gap-2">
      {tableColumnFilters.map(({ id: columnId, value }) => {
        const filter = columnMultiFilters?.find((c) => c.columnId === columnId);
        const label = filter?.label;
        return (
          <Fragment key={columnId}>
            {Object.entries(value).map(([key, value]) => {
              const item = filter?.items.find((item) =>
                typeof item === "object" ? item.value === value : item === value
              );
              let displayValue =
                typeof item === "object" ? item.label : item || value;
              if (filter?.formatPill)
                displayValue = filter.formatPill(displayValue);

              return (
                <div
                  key={key}
                  className="flex items-center rounded bg-grey-6 px-2 py-1"
                >
                  {label && (
                    <span className="mr-2 text-xs font-medium">{label}</span>
                  )}
                  <span className="text-xs">{displayValue}</span>
                  <Button
                    className="ml-2 p-0"
                    aria-label="remove filter"
                    onClick={() => removeFilter(columnId, key)}
                  >
                    <CloseIcon />
                  </Button>
                </div>
              );
            })}
          </Fragment>
        );
      })}
    </div>
  ) : null;
};
