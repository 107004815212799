import { useCallback } from "react";
import { toast } from "react-toastify";
import { EmployeeDocumentForm } from "types/employee";
import { ApolloError, gql, useMutation } from "@apollo/client";

export type CreateEmployeeDocumentsMutationArgs = {
  employeeId: string;
  name: string;
  type: string | undefined;
  template: string | undefined;
  option: string | undefined;
  description: string | undefined;
  googleDriveId: string | undefined;
};

export type EmployeeDocumentResponse = {
  data: {
    link: string | undefined;
    error: string | undefined;
    details: string | undefined;
  };
};

export const CREATE_EMPLOYEE_DOCUMENT = gql`
  mutation UpsertEmployeeDocument(
    $employeeId: String!,
    $name: String!,
    $type: String,
    $template: String,
    $option: String,
    $description: String,
    $googleDriveId: String,
  ) {
    data: upsert_employee_document(
      employee_id: $employeeId,
      name: $name,
      type: $type,
      template: $template,
      option: $option,
      description: $description,
      google_drive_id: $googleDriveId
    ) {
      link
      error
      details
    }
  }
`;

export const useCreateEmployeeDocument = () => {
  const [createEmployeeDocumentMutation, { loading, error, data }] = useMutation<
    EmployeeDocumentResponse,
    CreateEmployeeDocumentsMutationArgs
  >(CREATE_EMPLOYEE_DOCUMENT);

  const createEmployeeDocument = useCallback(
    async (
      documentData: EmployeeDocumentForm,
      employeeId: string,
    ) => {
      try {
        await createEmployeeDocumentMutation({
          variables: {
            employeeId: employeeId,
            name: documentData.name,
            type: documentData.type,
            template: documentData.template,
            option: documentData.option,
            description: documentData.description,
            googleDriveId: documentData.google_drive_id
          }
        });
      } catch (ex) {
        toast.error((ex as ApolloError)?.message);
      }
    },
    [createEmployeeDocumentMutation]
  );

  return {
    createEmployeeDocument,
    loading,
    error,
    response: data?.data,
  };
};
