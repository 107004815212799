import { ApolloError, gql, useMutation } from "@apollo/client";
import { useCallback } from "react";
import { toast } from "react-toastify";
import { EmployeeDocumentTemplates } from "types/employee";

export const GET_EMPLOYEE_DOCUMENT_TEMPLATES = gql`
  mutation getEmployeeDocumentsTemplates {
    data: get_employee_document_templates {
      templates {
        value
        label
      }
      form_templates {
        value
        label
      }
    }
  }
`;

type DocumentTemplates = {
  data: EmployeeDocumentTemplates,
}

export const useGetEmployeeDocumentTemplates = () => {
  const [getEmployeeDocumentTemplatesMutation, { loading, error, data }] = useMutation<
    DocumentTemplates
  >(GET_EMPLOYEE_DOCUMENT_TEMPLATES);

  const getEmployeeDocumentTemplates = useCallback(
    async () => {
      try {
        await getEmployeeDocumentTemplatesMutation();
      } catch (ex) {
        toast.error((ex as ApolloError)?.message);
      }
    },
    [getEmployeeDocumentTemplatesMutation]
  );

  return {
    getEmployeeDocumentTemplates,
    loading,
    error,
    templates: data?.data,
  };
};
