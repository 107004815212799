import { Drawer } from "components";
import { TableData } from "components/table/types";
import React, { Fragment, useCallback } from "react";
import { FiltersProps } from "../../Filters";
import { UseFilters } from "../../useFilters";
import {
  ColumnMultiFilterButtons,
  ColumnMultiFilterDropdownContent,
  ColumnMultiFilterItemsList,
  ColumnMultiFilterName,
} from "./components";
import { ADDITIONAL_FILTERS_KEY } from "./ColumnMultiFilters";

export type ColumnMultiFiltersMobileProps<D extends TableData> = Omit<
  UseFilters,
  "isFiltersSectionOpen" | "tableFiltersCount" | "applyCustomFilters"
> &
  Pick<
    FiltersProps<D>,
    "table" | "columnMultiFilters" | "columnAdditionalFilters"
  >;

export const ColumnMultiFiltersMobile = <D extends TableData>({
  applyFilters,
  checkedItems,
  setCheckedItems,
  columnMultiFilters,
  columnAdditionalFilters,
  closeOpenedFilter,
  handleCheck,
  handleChange,
  clearColumn,
  openedFilter,
  setFiltersSectionOpen,
  toggleOpenedFilter,
  clearFilters,
  table,
}: ColumnMultiFiltersMobileProps<D>) => {
  const closeDrawer = useCallback(() => {
    closeOpenedFilter();
    setFiltersSectionOpen(false);
  }, [closeOpenedFilter, setFiltersSectionOpen]);

  const isOpenAdditional = openedFilter === ADDITIONAL_FILTERS_KEY;
  const createChecked = () => {
    const obj: any = {};
    if (columnAdditionalFilters && columnAdditionalFilters.length) {
      columnAdditionalFilters.forEach((item) => {
        if (checkedItems[item.columnId]) {
          obj[item.label] = item.label.toString();
        }
      });
    }
    return obj;
  };
  const checkedAdditionalValues = createChecked();

  return (
    <Drawer
      isOpen
      title="Filters"
      onClose={closeDrawer}
      action={{
        title: "Clear filters",
        onAction: clearFilters,
        disabled: Object.keys(checkedItems).length === 0,
      }}
    >
      <div className="flex h-full flex-col justify-between gap-4">
        <div className="flex h-full flex-col">
          {columnMultiFilters?.map((filter, index) => {
            const isOpen = openedFilter === filter.columnId;
            const checkedValues = checkedItems[filter.columnId];
            const { columnId, withSearch, isTableFilteredWithCellData } =
              filter;
            const items = isTableFilteredWithCellData
              ? table
                  .getPreFilteredRowModel()
                  .flatRows?.map((row) => row.getValue<string>(columnId))
              : filter.items;

            if (filter.Component)
              return (
                <filter.Component
                  key={columnId}
                  filter={filter}
                  isOpen={isOpen}
                  checkedValues={checkedValues}
                  setCheckedItems={setCheckedItems}
                  handleChange={(item: string, value: string) =>
                    handleChange(columnId, item, value)
                  }
                  clearColumn={() => clearColumn(columnId)}
                  toggleOpenedFilter={() => toggleOpenedFilter(columnId)}
                  applyFilters={() => applyFilters(columnId)}
                  closeOpenedFilter={closeOpenedFilter}
                />
              );

            return (
              <Fragment key={columnId}>
                {index !== 0 && <hr className="my-3" />}
                <div
                  className={`flex basis-auto flex-col ${
                    isOpen ? "flex-1" : ""
                  }`}
                >
                  <ColumnMultiFilterName
                    checkedValues={checkedValues}
                    isOpen={isOpen}
                    toggleOpenedFilter={toggleOpenedFilter}
                    filter={filter}
                  />
                  {isOpen && (
                    <ColumnMultiFilterDropdownContent
                      withSearch={withSearch}
                      checkedValues={checkedValues}
                      handleCheck={handleCheck}
                      items={items}
                      columnId={columnId}
                    />
                  )}
                </div>
              </Fragment>
            );
          })}
          {!!columnAdditionalFilters?.length && (
            <Fragment>
              <hr className="my-3" />
              <div
                className={`flex basis-auto flex-col ${
                  isOpenAdditional ? "flex-1" : ""
                }`}
              >
                <ColumnMultiFilterName
                  checkedValues={checkedAdditionalValues}
                  isOpen={isOpenAdditional}
                  toggleOpenedFilter={toggleOpenedFilter}
                  filter={{
                    label: "Additional filters",
                    columnId: ADDITIONAL_FILTERS_KEY,
                    items: [],
                  }}
                />
                {isOpenAdditional &&
                  columnAdditionalFilters.map((filter) => (
                    <ColumnMultiFilterItemsList
                      key={filter.label}
                      checkedValues={checkedAdditionalValues}
                      handleCheck={(item) => handleCheck(filter.columnId, item)}
                      items={[filter.label]}
                      columnId={filter.columnId}
                    />
                  ))}
              </div>
            </Fragment>
          )}
        </div>
        <ColumnMultiFilterButtons
          onApply={() => {
            setFiltersSectionOpen(false);
            applyFilters();
          }}
          onCancel={closeDrawer}
        />
      </div>
    </Drawer>
  );
};
