const API = {
  login: "/career_path/oauth_login",
  logout: "/career_path/oauth_logout",
  config: "/erp/config",

  employeeReport: "/export/employee-report",
  projectReport: "/export/project-report",
  timesheetsReport: "/export/time-sheets-report",
  employeeSkillsReport: "/export/employee-skills-report/",
  workLogsReport: "/export/xls",
  workLogsReportMonthly: "/export/project-timesheet",

  absencesUpload: "/import_file/absences",
  absencesLimitsUpload: "/import_file/limits",

  employeeResumeUpload: "/resumes/upload",
};

export default API;
